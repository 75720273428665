.search-container{
   padding:1rem;
   border-top: 1px rgba(255,255,255,0.3) solid;
   margin-top: 12px;
}

.search-item{
  flex-direction: row;
  display: flex;
  padding :8px 12px;
}
.search-item-icon{
  justify-content: center;
  border-radius: 3;
  margin-right: 12px;
  height: 46px;
  min-width:46px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.text{
  color:white;
  &.sub{
    font-size: 12px;
    opacity: 0.5;
  }
}