.faq-box {
    margin-top: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-size: 14px;
}
