// Fontsgut

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import "./components/common/commonstyle.scss";
// Global Variables
$blue: #1d70aa;
$yellow: #ffcc00;
$grey: #5f717e;

@mixin box {
  border-radius: 3px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin icon {
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  margin-bottom: -1.3em;
  margin-left: 0.5em;
  background-size: 1em 1em;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

html {
  height: 100%;
  overflow: hidden;
  position: fixed;
}

body {
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-position: center;
  position: fixed;
  color: $grey;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

* {
  box-sizing: border-box;
}

// Globale Styling Items

.btn {
  @include box;
  display: inline-block;
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: #fff;
  font-weight: bold;
  &:active {
    box-shadow: none;
    transform: translateY(1px);
  }
}

.date {
  margin-top: 0.5rem;
  color: #ccc;
  .calendar {
    display: inline;
    height: 1rem !important;
    opacity: 0.3;
    margin-bottom: -2px;
  }
}

.switch-wrapper {
  position: relative;
  width: 100%;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

// Loading Clock

#Loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $blue;
  opacity: 0.5;
  z-index: 99;
  svg {
    height: 100px;
    width: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#Error {
  color: #fff;
  min-height: calc(100vh - 50px);
  @include center;
}

// Global Layout

#Layout {
  #Header {
    height: 50px;
    color: #fff;
    width: 100%;
    position: fixed;
    top: left;
    z-index: 0;
    display: flex;
    padding-left: 1rem;
    align-items: center;
    .yellow {
      color: $yellow;
      font-weight: bold;
    }
    .back {
      height: 50px;
      width: 50px;
      padding: 10px;
      margin-left: -15px;
    }
    .menuButton {
      position: absolute;
      top: 0px;
      right: 0;
      height: 50px;
      width: 50px;
      @include center;
      img {
        max-width: 20px;
        max-height: 20px;
        height: auto;
        width: auto;
      }
      &:active {
        transform: scale(0.8);
      }
    }
  }
  #Content {
    z-index: 0;
    position: fixed;
    height: calc(100% - 50px);
    width: 100%;
    top: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10vh;
  }
  #Background {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background-size: cover;
    left: 0;
    background-image: url(./assets/background.jpg);
    z-index: 0;
  }
  .search-icon {
    height: 36px;
    width: 36px;

    background-image: url(./assets/search.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 50%;
    margin-right: 1rem;
  }
  @keyframes inputAni {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  .search-input-container {
    position: relative;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.16);
    border: none;
    animation: inputAni 0.3s;
    width: 100%;
    margin-right: 1rem;
    height: 70%;
    color: white;

    font-size: 16px;
  }
  .search-input {
    border-radius: 16px;
    background-color: transparent;
    border: none;
    animation: inputAni 0.3s;
    width: 100%;
    margin-right: 1rem;
    height: 100%;
    color: white;
    padding-left: 12px;
    font-size: 16px;
  }
  .spinner {
    position: absolute;
    right: 8px;
    top: 0;
    height: 100%;
  }
}

#Login {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  .error {
    @include box;
    background: red;
    padding: 0.5rem;
    font-size: 12px;
    margin-top: 1rem;
    text-align: center;
  }
  #Form {
    position: relative;
    z-index: 10;
    width: 60vw;
    min-width: 200px;
    input[type="email"],
    input[type="password"],
    input:-webkit-autofill {
      font-family: "Lato", sans-serif;
      font-size: 16px;
      width: 100%;
      display: block;
      padding: 0.7rem 1rem 0.7rem 2.5rem;
      border: 0px;
      border-radius: 5px;
      margin-top: -1em;
      margin-bottom: 0.5rem;
    }
    .icon-password {
      background: url("./assets/password_icon.svg") no-repeat;
      @include icon;
    }
    .icon-user {
      background: url("./assets/user_icon.svg") no-repeat;
      @include icon;
    }

    input[type="submit"] {
      @include box;
      background: $yellow;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      border-radius: 5px;
      appearance: none;
      width: 100%;
      padding: 0.7rem 1rem;
      border: 0px;
      margin-top: 1rem;
      &:active {
        box-shadow: none;
        transform: translateY(1px);
      }
    }
    img {
      width: 100px;
      height: auto;
      margin: 0 auto;
      display: block;
      margin-bottom: 3rem;
    }
    label {
      display: block;
      opacity: 0.5;
      margin-bottom: 0.2rem;
    }
  }
}

#Dashboard {
  padding: 1rem;
  .news-title {
    color: #fff;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .news-close {
      height: 1.3rem;
      width: 1.3rem;
      background-image: url(./assets/icons-close.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  h3 {
    color: #fff;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
    font-weight: normal;
  }
  #Welcome {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 1rem;
    position: relative;
    &:after {
      content: "";
      animation: grow 0.2s ease forwards;
      animation-delay: 0.5s;
      position: absolute;
      height: 3px;
      background: $yellow;
      left: 0;
      bottom: 0;
      border-radius: 10px;
      @keyframes grow {
        from {
          width: 0;
        }
        to {
          width: 70%;
        }
      }
    }
    padding-bottom: 0.5rem;
  }
  #Aktuell {
    @include box;
    display: block;
    text-decoration: none;
    padding: 1.5rem;
    color: #fff;
    margin-bottom: 2rem;
    background: #e74c3c;
    text-align: center;
    img {
      height: 30px;
      width: auto;
    }
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  .bereiche {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(9, 1fr);
    // grid-template-rows: 1.3fr 1fr 1fr;
    .bereich {
      font-size: 12px;
      text-decoration: none;
      .title {
        text-align: center;
        color: #fff;
      }
      .icon {
        @include center;
        @include box;
        position: relative;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 0.5rem;

        &.nineeuro {
          display: flex;
          align-items: center;
          justify-content: center;
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            color: white;
            font-weight: bold;
          }
        }
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
        img {
          width: 50px;
          height: 50px;
        }
        .number {
          @include center;
          position: absolute;
          height: 16px;
          width: 16px;
          font-size: 12px;
          color: #fff;
          border-radius: 50%;
          background: red;
          top: -3px;
          right: -3px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
      }
      &:active {
        .icon {
          box-shadow: none;
          transform: translateY(1px);
        }
      }
    }
  }
  @mixin icon {
    height: 30px;
    width: 30px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .footer-bar {
    margin-top: 30px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    span {
      margin-top: 15px;
    }
    a {
      color: white;
      text-decoration: none;
    }
    .footer-bar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .icon-kontakt {
      @include icon();
      background-image: url(./assets/sws-contact.svg);
    }
    .icon-download {
      @include icon();
      background-image: url(./assets/sws-downloads.svg);
    }
    .icon-faq {
      @include icon();
      background-image: url(./assets/sws-help.svg);
    }
    .icon-option {
      @include icon();
      background-image: url(./assets/sws-settings.svg);
    }
  }
}

#Bereich {
  .header-bild {
    height: 20vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .intro-container {
    display: flex;
    flex-direction: row;
    color: #fff;
    .header-title {
      margin: 1rem;

      font-size: 1.5rem;
    }
  }

  .headerbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 0.5rem;
    border-top: solid #ccc 1px;
    border-bottom: solid #ccc 1px;
    margin-top: 1rem;
    .headerbar-item {
      color: white;
      padding: 0.5rem 0;
      &.aktive {
        color: #fdcc2b;
        border-bottom: 2px solid #fdcc2b;
      }
    }
  }

  .intro {
    height: 10vh;
    width: 10vh;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;
    margin-top: -1rem;
    margin-left: 1rem;
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
  }
  .meldungen {
    position: relative;
    z-index: 1;
    margin: 1rem;

    .meldung {
      @include box;
      overflow: hidden;
      background: #fff;
      margin-bottom: 1rem;
      .meldung-header {
        display: flex;
        justify-content: space-between;
        padding: 0.8rem;
        .meldung-header-left {
          display: flex;
          .meldung-icon {
            border-radius: 50%;
            display: block;
            height: 36px;
            width: 36px;
            background-size: 60%;
            background-position: center;
            background-repeat: no-repeat;
          }
          .meldung-bereich {
            margin-left: 0.5rem;
            .date {
              margin: 0 !important;
            }
          }
        }
        .meldung-viewed {
          height: 36px;
          width: 36px;
          background-image: url(./assets//unviewed.svg);
          background-position: center;
          background-repeat: no-repeat;
          &.viewed {
            background-image: url(./assets//viewed.svg);
          }
        }
      }
      h2 {
        margin: 0;
        margin-bottom: 0.5rem;
      }
      .bild {
        height: 20vh;
        background-size: cover;
        background-position: center;
        position: relative;
        .ninelabel {
          padding: 12px;
          color: white;
          font-size: 2rem;
          font-weight: bold;
          position: absolute;
          right: 0;
          top: 36px;
          background-color: #fdcc2b;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
      }
      .content {
        padding: 1.5rem;
      }
      .teaser {
        margin: 1rem 0;
      }
      .date {
        color: #ccc;
        margin-bottom: 1rem;
        .calendar {
          display: inline;
          height: 1rem;
          opacity: 0.3;
          margin-bottom: -2px;
        }
      }
    }
  }
  &.News {
    .meldungen {
      margin: 0;
    }
  }
}

#Meldung {
  .meldung-content {
    p,
    span,
    table {
      font-size: 1rem !important;
    }
  }
  .border {
    height: 20px;
  }

  .content {
    @include box;
    background: #fff;
    margin: 1rem 1rem 1.5rem 1rem;
    overflow: hidden;
    .meldung-header {
      display: flex;
      justify-content: space-between;
      padding: 0.8rem;
      .meldung-header-left {
        display: flex;
        .meldung-icon {
          border-radius: 50%;
          display: block;
          height: 36px;
          width: 36px;
          background-size: 60%;
          background-position: center;
          background-repeat: no-repeat;
        }
        .meldung-bereich {
          margin-left: 0.5rem;
          .date {
            margin: 0 !important;
          }
        }
      }
      .meldung-viewed {
        height: 36px;
        width: 36px;
        background-image: url(./assets//unviewed.svg);
        background-position: center;
        background-repeat: no-repeat;
        &.viewed {
          background-image: url(./assets//viewed.svg);
        }
      }
    }
    > .bild {
      height: 200px;
      background-size: cover;
      background-position: center;
    }
    .inner {
      .bild {
        margin: 1rem 0;
      }
      .bild > div > div {
        transform: translateZ(1000px);
      }
      img {
        max-width: 100%;
        height: auto;
      }
      padding: 1rem 1.5rem 1rem 1.5rem;
      > div > h1 {
        display: none;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
      font-size: 1.5rem;
    }

    .pdf {
      margin: 1rem 0;
      a {
        background: $blue;
      }
    }

    video {
      margin: 1rem 0;
      max-width: 100%;
      height: auto;
    }
    table {
      margin: 1rem 0;
      overflow-x: scroll;
      display: block;
      width: 100%;
      border-spacing: 3px;
      border-collapse: separate;
      td {
        background-color: #eee;
        padding: 0.5rem;
        vertical-align: top;
        min-width: 100px;
        p {
          margin: 0;
        }
      }
      tr:nth-child(even) {
        td {
          background: #fff;
        }
      }
    }
    .inner > div > .bild {
      max-width: 100%;
      height: auto;
      margin: 1rem auto;
    }
  }
}

#Settings {
  padding: 2rem;
  text-align: center;

  .btn {
    display: block;
    margin: 2rem auto auto auto;
    border: 1px solid #fff;
    width: 15em;
  }
  .btn.abmelden {
    display: absolute;
    margin: 2rem auto auto auto;
    background: $yellow;
  }
  .successHint {
    font-size: 12px;
    font-weight: bold;
    color: #fff;

    margin: 1rem auto;
  }
}

#setPassword {
  display: flex;
  margin-top: 5em;
  justify-content: center;
  color: #fff;
  height: 100%;
  width: 100%;
  z-index: 10;
  .error {
    @include box;
    background: red;
    padding: 0.5rem;
    font-size: 12px;
    margin-top: 1rem;
    text-align: center;
  }
  .hint {
    padding: 0.5rem;
    font-size: 12px;
    margin-top: 1rem;
    text-align: center;
  }

  #Form {
    position: relative;
    z-index: 10;
    width: 60vw;
    min-width: 200px;
    input[type="password"] {
      font-family: "Lato", sans-serif;
      font-size: 16px;
      width: 100%;
      display: block;
      padding: 0.7rem 1rem 0.7rem 3rem;
      border: 0px;
      border-radius: 5px;
      margin-top: 1em;
      margin-bottom: 1rem;
      background: url("./assets/password_icon.svg") no-repeat 5%;
      background-size: 1em 1em;
      background-color: #fff;
    }
    input[type="submit"] {
      @include box;
      background: $yellow;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      border-radius: 5px;
      appearance: none;
      width: 100%;
      padding: 0.7rem 2rem;
      border: 0px;
      margin-top: 1rem;
      &:active {
        box-shadow: none;
        transform: translateY(1px);
      }
      &:disabled {
        background: $grey;
      }
    }
    label {
      display: block;
      opacity: 0.5;
      margin-top: 1em;
      margin-bottom: 0.2rem;
    }
  }
}

@media (min-width: 1200px) {
  #root {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    @include center;
  }
  #Login {
    position: absolute;
  }
  #Layout {
    position: relative;
    width: 375px;
    height: 675px;
    overflow: hidden;
    margin: 0 auto;
  }
  #Background {
    position: absolute;
  }
  #Layout {
    #Header {
      position: absolute;
    }
    #Content {
      position: absolute;
    }
  }
  #Meldung .bild {
    height: 200px;
  }
}

#Privacy {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(24, 82, 148, 0.9);
  z-index: 999999;
  color: #fff;
  padding: 2rem;

  .content {
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.3rem;
    }
    box-sizing: border-box;
    overflow-y: scroll;
    margin-bottom: 4rem;
    > div {
      display: inline;
    }
  }
  .button {
    font-weight: bold;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: #ffcc00;
    color: #fff;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 80%;
    text-align: center;
  }
}

.meldung-box {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  .img {
    flex-shrink: 0;
    height: 42px;
    width: 42px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  #ansprechpartner {
    font-size: 12px !important;
    color: #b7b7b7;
  }
  .info {
    margin-left: 10px;

    display: flex;
    flex-direction: column;
    .name {
      font-size: 16px;
    }
  }
  .partner-email {
    display: flex;
    flex-direction: column;

    float: right;
    .icon-phone {
      height: 13px;
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(./assets/sws-phone.svg);
    }
    .icon-email {
      margin-top: 8px;
      height: 13px;
      width: 18px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url(./assets/sws-email.svg);
    }
  }
}

#Menu {
  padding: 1rem;
  .user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .user-image {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .user-info {
      color: white;
      margin-left: 0.5rem;
    }
  }
  .menu-item-container {
    color: white;
    margin-top: 1rem;
    .menu-item {
      padding-left: 15%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 1px rgba(255, 255, 255, 0.3) solid;
      background-repeat: no-repeat;
      background-position: 0% 50%;
      background-size: 8%;
      @media (min-width: 820px) {
        background-size: 40px;
        background-position: 5% 50%;
      }
      &.news {
        background-image: url(./assets/news_v2.svg);
      }
      &.bereich {
        background-image: url(./assets/bereich_v2.svg);
      }
      &.tools {
        background-image: url(./assets/tools_v2.svg);
      }
      &.downloads {
        background-image: url(./assets/downloads_v2.svg);
      }
      &.kontakte {
        background-image: url(./assets/kontakte_v2.svg);
      }
      &.einstellung {
        background-image: url(./assets/setting_v2.svg);
      }
    }
  }
}

#form-builder-container {
  input {
    width: 100%;
    height: 32px;
    font-size: 16px;
    border-radius: 3px;
    padding-left: 0.5rem;
    border: 0px;
  }
  select {
    width: 100%;
    height: 32px;
    font-size: 16px;
    border-radius: 3px;
    padding-left: 0.5rem;
    border: 0px;
  }
  button:disabled {
    opacity: 0.5;
  }
}
