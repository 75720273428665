#footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;

  height: 64px;
  border-top: 1px #aaa solid;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  z-index: 99;
  background-color: #124b78;
  @media (min-width: 1200px) {
    max-width: 375px;
  }
  .footer-item {
    width: 16.6%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    @media (min-width: 820px) {
      background-size: 30px;
    }
    &.nine {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      .nineeuro {
        height: 60%;
        border-radius: 10px;
        border: 2px solid #fff;
        width: 70%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        color: #fdcc2b;
        font-weight: bold;
        font-size: 8px;
        color: #fff;
      }
      &.active {
        .nineeuro {
          border-color: #fdcc2b;
          color: #fdcc2b;
        }
        border-top: 2px solid #fdcc2b;
      }
    }

    &.news {
      background-image: url(../../assets/news_v2.svg);
      &.active {
        background-image: url(../../assets/news_active_v2.svg);
        border-top: 2px solid #fdcc2b;
      }
    }
    &.bereich {
      background-image: url(../../assets/bereich_v2.svg);
      &.active {
        background-image: url(../../assets/bereich_active_v2.svg);
        border-top: 2px solid #fdcc2b;
        transition: all 0.3s;
      }
    }
    &.tools {
      background-image: url(../../assets/tools_v2.svg);
      &.active {
        background-image: url(../../assets/tools_active_v2.svg);
        border-top: 2px solid #fdcc2b;
        transition: all 0.3s;
      }
    }
    &.downloads {
      background-image: url(../../assets/downloads_v2.svg);
      &.active {
        background-image: url(../../assets/downloads_active_v2.svg);
        border-top: 2px solid #fdcc2b;
        transition: all 0.3s;
      }
    }
    &.menu {
      background-image: url(../../assets/menu_v2.svg);
      &.active {
        background-image: url(../../assets/menu_active_v2.svg);
        border-top: 2px solid #fdcc2b;
        transition: all 0.3s;
      }
    }
  }
}
