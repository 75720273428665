.content.lunchpaket {
	padding: 2rem;
	label {
		margin-bottom: 0.3rem;
		display: block;
		color: #fff;
		pointer-events: none;
		font-size: 1rem;
	}
	.field-group {
		// margin: 1rem 0;
		padding: 0.5rem 0;
		margin-bottom: 10px;

		&.sub {
			border-bottom: 0px;
			margin-left: 1rem;
			margin-bottom: 10px;
		}
	}
	.mahlzeit {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	}
	.description {
		color: #fff;
		opacity: 0.5;
	}
	select,
	input[type='text'],
	input[type='date'],
	input[type='tel'],
	textarea {
		font-size: 1rem;
		appearance: none;
		width: 100%;
		padding: 0.5rem 1rem;
		border-radius: 3px;
		border: 0px;
		font-family: 'Lato', sans-serif;
	}

	select {
		background-color: #fff;
		background-repeat: no-repeat;
		background-position: top 50% right 10px;
		background-size: 1rem;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzIiIGhlaWdodD0iMzIiCnZpZXdCb3g9IjAgMCAzMiAzMiIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNIDQuMjE4NzUgMTAuNzgxMjUgTCAyLjc4MTI1IDEyLjIxODc1IEwgMTUuMjgxMjUgMjQuNzE4NzUgTCAxNiAyNS40MDYyNSBMIDE2LjcxODc1IDI0LjcxODc1IEwgMjkuMjE4NzUgMTIuMjE4NzUgTCAyNy43ODEyNSAxMC43ODEyNSBMIDE2IDIyLjU2MjUgWiI+PC9wYXRoPjwvc3ZnPg==');
	}

	textarea {
		flex-wrap: nowrap;
		height: 150px;
	}
	input[type='radio'],
	input[type='checkbox'] {
		float: left;
		height: 1rem;
		width: 1rem;
		appearance: none;
		margin-right: 1rem;
		background: #fff;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		border: 0px;
		border-color: transparent;
		align-items: center;
		pointer-events: none;
		&:checked {
			background: #ffcc00;

			&:after {
				font-size: 1rem;
				content: '✖';
				color: #000;
			}
		}
	}
	.mahlzeit label {
		margin-left: 2rem;
	}
	input[type='radio'] {
		border-radius: 50%;
	}
	h2,
	h3 {
		font-size: 1rem;
		opacity: 0.5;
		text-transform: uppercase;
		margin-bottom: 2rem;
		margin: 0;
		color: #fff;
	}
	h3 {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	.price {
		float: right;
		opacity: 0.5;
	}
	.summary {
		color: #fff;
		padding: 1rem 0;
		.total {
			float: right;
			opacity: 0.5;
		}
	}
	button {
		background: #ffcc00;
		color: #fff;
		appearance: none;
		border: 0px;
		font-size: 1rem;
		font-weight: bold;
		font-family: 'Lato', sans-serif;
		padding: 0.5rem 1rem;
		width: 100%;
		border-radius: 3px;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
	}

	&.success {
		color: #fff;
		img {
			height: 100px;
			width: auto;
		}
		text-align: center;
	}
}

.sending {
	position: fixed;
	height: 100vh;
	width: 100vw;
	bottom: 0;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	img {
		height: 100px;
		width: auto;
		animation: send 3s infinite both;
		transform: translateX(-100vw);
	}
	@keyframes send {
		0% {
			transform: translateX(-100vw);
		}

		100% {
			transform: translateX(100vw);
		}
	}
}
