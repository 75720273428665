.container {
    padding: 0px 1rem;
    font-size: 12px;
    a {
        text-decoration: none;
        color: #5f717e;
    }
    .title {
        font-size: 1.8rem;
        color: #fff;
    }
    .box-container {
        margin-top: 0.5rem;

        .fahr-dienst {
            background-color: #fecc2f;
            height: 26px;
            color: #fff;
            padding: 5px 1rem;
            border-radius: 5px 5px 0 0;
            font-size: 14px;
        }
        .box {
            display: flex;
            align-items: center;
            padding: 1rem;
            border-top: solid 1px rgba(0, 0, 0, 0.1);
            background-color: #fff;
            justify-content: space-between;
            .img {
                height: 42px;
                width: 42px;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
            }
            .info {
                margin-left: 10px;
                width: fit-content;
                display: flex;
                flex-direction: column;
                .name {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .partner-email {
                display: flex;
                flex-direction: column;
                float: right;
                .icon-phone {
                    height: 13px;
                    width: 18px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url(../../../assets/sws-phone.svg);
                }
                .icon-email {
                    margin-top: 8px;
                    height: 13px;
                    width: 18px;
                    background-size: contain;
                    background-position: center;
                    background-image: url(../../../assets/sws-email.svg);
                }
            }
        }
        .kontakt-presse {
            background-color: #1482d8;
            height: 26px;
            color: #fff;
            padding: 5px 1rem;
            font-size: 14px;
        }
    }
}
